import request from '@/utils/request'

export function getTaskList(data) {
    return request({
        url: `/projectTask/page?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
        method: 'POST',
        data: data.queryParam,
    })
}

export function getTaskTypeList(params) {
    return request({
        url: `/taskCategory/page`,
        method: 'GET',
        params,
    })
}

export function createTask (data) {
    return request({
        url: data.id ? `/projectTask/update/${data.id}` : `/projectTask/create`,
        method: 'POST',
        data
    })
}

export function deleteTask(data) {
    return request({
        url: '/projectTask/delete',
        method: 'POST',
        data,
    })
}

export function getProjectMember(params) {
    return request({
        url: '/projectInfo/getProjectMemebers',
        method: 'GET',
        params,
    })
}

export function getTaskMember(data) {
    return request({
        url: '/projectTask/listAllTaskMembers',
        method: 'POST',
        data,
    })
}








